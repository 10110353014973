import { Component, Input } from '@angular/core';

@Component({
    selector: 'empty-list',
    templateUrl: 'empty-list.component.html'
})
export class EmptyListComponent
{
    @Input()
    public text = 'Nothing to show here!';
}
