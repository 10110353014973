import { Component, Inject } from '@angular/core';
import {  MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


export interface ConfirmDialogData 
{
    title: string;
    message: string;
    confirm?: string;
    dismiss?: string;
    onConfirm?: string;
    onDismiss?: string;
}

@Component({
    selector: 'ddf-confirm-dialog',
    templateUrl: 'confirm.dialog.component.html'
})
export class ConfirmDialogComponent
{

    constructor(
        @Inject(MAT_DIALOG_DATA) 
        public data: ConfirmDialogData,
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        private snackbar: MatSnackBar

    ) { }

    public onConfirm(): void {
        // Close the dialog, return true
        this.dialogRef.close(true);
        if(this.data.onConfirm) this.snackbar.open(this.data.onConfirm);
    }

    public onDismiss(): void {
        // Close the dialog, return false
        this.dialogRef.close(false);
        if(this.data.onDismiss) this.snackbar.open(this.data.onDismiss);
    }
}