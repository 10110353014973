import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { RouterModule } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { getIcons } from './icons';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    imports: [
        BrowserModule,
        RouterModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        SharedModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
        getIcons().forEach((icon) => {
            if (!icon || icon.length <= 0) { return; }

            matIconRegistry.addSvgIcon(
                icon, domSanitizer.bypassSecurityTrustResourceUrl(`./assets/icons/svg/${icon}.svg`)
            );
        })      
    }
}