import { Component, Input } from '@angular/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@Component({
    selector: 'ddf-loader',
    templateUrl:'loader.component.html'
})
export class LoaderComponent 
{
    @Input() public message: string | undefined;
    @Input() public progressBar: MatProgressBarModule;

    constructor(
    )
    {
       
    }
}
