import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '@core/services/auth.service';

export interface IMenuItem 
{
    name: string;
    icon: string;
    link?: string;
    onClick?: Function;
}

@Component({
    selector: 'sidenav-menu',
    templateUrl: 'menu.component.html'
})
export class MenuComponent {
    
    public menu: IMenuItem[];

    @Input('sidenav')
    private sidenav: MatSidenav;

    @Input('isMobile')
    private isMobile: boolean;

    constructor(
        private router: Router,
        private authService: AuthService
    ) {


        this.menu = [
            {
                name: 'Dashboard',
                link: '/dashboard',
                icon: 'view-dashboard',
            },
            {
                name: 'Collectives',
                link: '/collective',
                icon: 'account-multiple',
            },
            {
                name: 'Groups',
                link: '/groups',
                icon: 'account-multiple',
            },
            {
                name: 'Messages',
                link: '/message',
                icon: 'forum-outline',
            },
            {
                name: 'Profile',
                link: '/profile',
                icon: 'account-box-outline',
            },
            {
                name: 'Sign-out',
                icon: 'logout',
                onClick: () => this.signoutAsync()
            }
        ];
    }

    public async navigateAsync(ev: Event, menuItem: IMenuItem): Promise<void> 
    {
        ev.preventDefault();
        if (this.isMobile) this.sidenav.close();
        if (menuItem.link) this.router.navigateByUrl(menuItem.link);
        if (menuItem.onClick) menuItem.onClick();
    }

    private async signoutAsync(): Promise<void> 
    {
        await this.authService.signoutAsync().then(() => {
            return this.router.navigateByUrl('/auth');
        });
    }
}