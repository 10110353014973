import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from '@core/services/token.service';
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { environment } from '@environments/environment';

export enum Application
{
    Unknown = 0,
    Portal = 1,
    CustomerApp = 2,
    TrainerApp = 3,
    Website = 4
}

export interface SigninData
{
    username: string;
    password: string;
}

enum SignInStatus  {
    Success=1,
    Failure=-1
}
export interface SigninResult
{
    status: SignInStatus;
    text?: string | null;
}

export interface SignupResult
{
    commandId: string;
    data?: any;
    executionTime: number;
    isCompleted: boolean;
    isSuccess: boolean;
    message?: string;
}

export interface RequestPasswordReset 
{
    nationalId: string;
    phone: string;
    password: string;
}

@Injectable({providedIn: 'root'})
export class AuthService
{
    constructor(
        private httpClient: HttpClient,
        private tokenService: TokenService,
        private commandService: CommandService
    ) {}
    
    public signupAsync(data: any): Promise<SignupResult>
    {
        const url = `${environment.authUrl}/signup`;
        return this.httpClient.post<SignupResult>(url, data, {
            withCredentials: false,
        }).toPromise();
    }

    public async signinAsync(data: SigninData): Promise<SigninResult>
    {
        let result: SigninResult;
        const url = `${environment.authUrl}/signin`;
        const headers = new HttpHeaders({
            AppId: environment.appId.toString()
        });

        const response = await this.httpClient.post<{token?: string, message?: string}>(url, data, {
            withCredentials: true,
            headers
        }).toPromise();

        if (response && response.token)
        {
            this.tokenService.set(response.token);
            result = { status: SignInStatus.Success, text: 'Successfully signed in' };
        }
        else
        {
            this.tokenService.set(null);
            result = { status: SignInStatus.Failure, text: response.message || '' };
        }

        return result;
    }

    public async refreshAsync(): Promise<boolean>
    {
        const url = `${environment.authUrl}/refresh`;
        const body = {};

        const response = await this.httpClient
            .post<{token: string}>(url, body, { withCredentials: true })
            .toPromise();

        this.tokenService.set(response && response.token || null);
        return response && !!response.token;
    }

    public async signoutAsync(): Promise<boolean>
    {
        const url = `${environment.authUrl}/signout`;
        const data = {};
        const response = await this.httpClient
            .post<{token: string}>(url, data, { observe: 'response', withCredentials: true })
            .toPromise();

        this.tokenService.set(null);
        return response && response.ok;
    }
    
    public requestPasswordAsync(data: RequestPasswordReset): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('UserUpdatePassword', data);
    }
}
