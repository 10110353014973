import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Logger } from '@core/helpers/logger';
import { TokenService } from '@core/services/token.service';


export interface ICommandResponse
{
    commandId: string;
    data?: any;
    executionTime: number;
    isCompleted: boolean;
    isSuccess: boolean;
    message?: string;
}

@Injectable({providedIn: 'root'})
export class CommandService
{
    constructor(
        private httpClient: HttpClient,
        private tokenService: TokenService,
    ){}

    public async publishAsync(commandName: string, command: {}): Promise<ICommandResponse>
    {
        const appId: string = environment.appId.toString();
        const authorization: string = `Bearer ${this.tokenService.token}`;
        const headers = new HttpHeaders({
            Authorization: authorization,
            AppId: appId,
            Command: commandName,
        });

        const response = this.httpClient
            .post<ICommandResponse>(`${environment.commandUrl}`, command, { headers, withCredentials: true })
            .toPromise();

        Logger.log(commandName, command, response);
        return response;
    }
}
