import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Logger } from '@core/helpers/logger';
import { TokenService } from '@core/services/token.service';

export interface IQueryResponse<T>
{
    queryId: string;
    result: T;
    executionTime: number;
    isSuccess: boolean;
    message?: string;
}

@Injectable({ providedIn: 'root' })
export class QueryService
{
    constructor(
        private httpClient: HttpClient,
        private tokenService: TokenService,
    ) { }

    public async queryAsync<T>(queryName: string, query = {}): Promise<IQueryResponse<T>>
    {
        const appId: string = environment.appId.toString();
        const headers = new HttpHeaders({
            AppId: appId,
            Query: queryName,
            Authorization: `Bearer ${this.tokenService.token}`
        });

        const response = await this.httpClient
            .post<IQueryResponse<T>>(`${environment.queryUrl}`, query, { headers, withCredentials: true })
            .toPromise();

        Logger.log(queryName, query, response);
        return response;
    }
}
