import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBarConfig, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientInterceptor } from '@core/interceptors/http.interceptor';
import { AuthService } from '@core/services/auth.service';
import { BreakpointService } from '@core/services/breakpoint.service';
import { CommandService } from '@core/services/command.service';
import { QueryService } from '@core/services/query.service';
import { TokenService } from'@core/services/token.service';


@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule,
    ],
    providers: [
        AuthService,
        BreakpointService,
        CookieService,
        CommandService,
        QueryService,
        TokenService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpClientInterceptor,
            multi: true,
            deps: [TokenService, AuthService]
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                role: 'dialog',
                closeOnNavigation: true,
                autoFocus: false,
                hasBackdrop: true,
            } as MatDialogConfig
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 2500,
                panelClass: 'ddf-snackbar-panel',
                horizontalPosition: 'center',
                verticalPosition: 'bottom'
            } as MatSnackBarConfig
        },
        
    ],
    declarations: [],
    exports: []
})
export class CoreModule {}
