// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appId: 3,
  envName: 'staging',
  appUrl: 'https://trainer.ddf.services',
  authUrl: 'https://auth.ddf.services',
  cbiUrl: 'https://cbi.ddf.services',
  commandUrl: 'https://command.ddf.services',
  queryUrl: 'https://query.ddf.services',
  fileUrl: 'https://file.ddf.services',
  reportUrl: 'https://report.ddf.services',
  ssoUrl: 'https://sso.ddf.services',
  firebase: {
    apiKey: "AIzaSyDDqxXeiWpVij9EVcuMBH3AzemY0naCg1Y",
    authDomain: "ddfinance-trainer-staging.firebaseapp.com",
    projectId: "ddfinance-trainer-staging",
    storageBucket: "ddfinance-trainer-staging.appspot.com",
    messagingSenderId: "1026630997581",
    appId: "1:1026630997581:web:e10cdea94ec2d82ef52e04",
    measurementId: "G-HL0ZS0DXLC"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
