<mat-sidenav-container id="wrapper" class="flex layout-fill">

    <mat-sidenav id="sidenav" #sidenav [ngClass]="currentBreakpoint" [fixedInViewport]="true" [opened]="!isMobile" [mode]="isMobile ? 'over' : 'side'" [disableClose]="!isMobile">
        <sidenav-menu [sidenav]="sidenav" [isMobile]="isMobile"></sidenav-menu>
    </mat-sidenav>

    <mat-sidenav-content>

        <mat-toolbar class="tw-flex tw-justify-between">
            <button type="button" aria-label="menu icon" 
            class="tw-text-brand-default tw-text-center tw-font-medium hide-gt-sm"
            (click)="sidenav.toggle()">
                <mat-icon svgIcon="menu"></mat-icon>
            </button>
            <img class="tw-max-h-full tw-max-w-full tw-scale-50" src="./assets/images/ddfinance-logo.png" alt="DDFinance" />
            <button class="tw-text-brand-default tw-text-center tw-text-2xl" type="button" aria-label="Account" [routerLink]="['/profile']" >
                <mat-icon svgIcon="account-circle"></mat-icon>
            </button>
        </mat-toolbar>

        <router-outlet></router-outlet>

    </mat-sidenav-content>

</mat-sidenav-container>