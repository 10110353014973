import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { TokenService } from '@core/services/token.service';

@Injectable({providedIn: 'root'})
export class AuthGuard
{
    constructor(
        private router: Router,
        private tokenService: TokenService,
        private authService: AuthService
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>
    {
        return this.isAuth();
    }

    public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>
    {
        return this.isAuth();
    }

    public canLoad(route: Route, segments: UrlSegment[]): Promise<boolean>
    {
        return this.isAuth();
    }

    private async isAuth(): Promise<boolean>
    {
        if (this.tokenService.isAuth) return true;
        const isReloaded = await this.authService.refreshAsync();
        if (isReloaded) return true;

        this.router.navigateByUrl('/auth');
        return false;
    }
}
