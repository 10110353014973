import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { WrapperComponent } from '@shared/components/wrapper/wrapper.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('@auth/auth.module').then(x => x.AuthModule)
  },
  {
    path: '',
    component: WrapperComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('@dashboard/dashboard.module').then(x => x.DashboardModule)
      },
      {
        path: 'collective',
        loadChildren: () => import('@collectives/collectives.module').then(x => x.CollectivesModule)
      },

      {
        path: 'groups',
        loadChildren: () => import('@groups/groups.module').then(x => x.GroupsModule)
      },
      {
        path: 'message',
        loadChildren: () => import('@messages/messages.module').then(x => x.MessagesModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('@profile/profile.module').then(x => x.ProfileModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'collective',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
