<section class="page layout-column">

    <div class="page-header layout-row layout-align-space-between-center">

        <button mat-icon-button class="btn is-primary" *ngIf="returnUrl" [routerLink]="returnUrl">
            <mat-icon svgIcon="chevron-left"></mat-icon>
        </button>
        
        <div>

            <h2 class="title inverse tw-text-white">
                <small *ngIf="supertitle" class="super">{{supertitle}}</small>
                {{title}}
                <small *ngIf="subtitle" class="sub">{{subtitle}}</small>
            </h2>

        </div>

        <div class="text-right hide-xs hide-sm">

            <button type="button" mat-button class="btn tw-text-white" (click)="doClickAsync(m)" *ngFor="let m of menu">
                <mat-icon [svgIcon]="m.icon" class="tw-text-white"></mat-icon>
                <div class="tw-text-white">{{m.name}}</div>
            </button>
        </div>

        <div class="text-right hide-gt-sm">
            <ng-container *ngIf="menu && menu.length > 0">
                <button type="button" mat-icon-button class="btn" [matMenuTriggerFor]="tplMenu">
                    <mat-icon svgIcon="dots-vertical" class="tw-text-white"></mat-icon>
                </button>
        
                <mat-menu #tplMenu="matMenu">
                    <button type="button" class="btn tw-text-white" mat-menu-item (click)="doClickAsync(m)" *ngFor="let m of menu">
                        <mat-icon [svgIcon]="m.icon" class="tw-text-white"></mat-icon>
                        {{m.name}}
                    </button>
                </mat-menu>
            </ng-container>

        </div>

    </div>

    <ng-content></ng-content>
</section>