import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '@core/services/auth.service';
import { Injectable } from '@angular/core';


@Injectable()
export class HttpClientInterceptor implements HttpInterceptor
{
    constructor(private authService: AuthService) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any>
    {
        return next.handle(request)
            .pipe(
                catchError((error) =>
                {
                    // Check the response status code
                    if (error instanceof HttpErrorResponse &&
                        !request.url.includes('/signin') &&
                        error.status === 401)
                    {
                        return this.handleUnauthorizedError(request, next);
                    }

                    return throwError(() => error);
                }),
            );
    }

    private handleUnauthorizedError(request: HttpRequest<any>, next: HttpHandler): Observable<any>
    {

        return from(this.authService.refreshAsync())
            .pipe(
                switchMap(() =>
                {
                    return next.handle(request)
                }),
                catchError((error) => throwError(error))
            );
    }
}