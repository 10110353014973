import { Component } from '@angular/core';
import { BreakpointService } from '@core/services/breakpoint.service';

@Component({
    templateUrl: 'wrapper.component.html'
})
export class WrapperComponent
{
    public currentBreakpoint: string;

    constructor(private breakpointService: BreakpointService)
    {

        this.breakpointService.current$
            .subscribe(current =>
            {
                this.currentBreakpoint = `is-${current}`;
            });
    }

    public get isMobile(): boolean
    {
        return this.currentBreakpoint === 'is-xs' || this.currentBreakpoint === 'is-sm';
    }
}
