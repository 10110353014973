import { Injectable } from '@angular/core';
import { MatDateFormats, NativeDateAdapter } from '@angular/material/core';
import * as dayjs from 'dayjs';

export const MY_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'YYYY',
    },
    display: {
        dateInput: 'YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'YYYY',
        monthYearA11yLabel: 'YYYY',
    },
};

@Injectable()
export class DayjsDateAdapter extends NativeDateAdapter
{
    format(date: Date, displayFormat: any): string
    {
        if (displayFormat === 'YYYY')
        {
            return dayjs(date).format('YYYY');
        }
        return super.format(date, displayFormat);
    }
}

