import { Component, Input } from '@angular/core';


@Component({
    selector: 'skeleton',
    templateUrl: 'skeleton.component.html'
})
export class SkeletonComponent
{
    @Input() 
    public items: number = 10;
    
    constructor() {}
}
